import React, { Component } from 'react';
import styled from 'styled-components';

import {withComponents} from "../component/ComponentProvider";

const PreviewIframe = styled.iframe`
    width:100%;
    height:100%;
    border:none;
    flex:0 1 auto;
`;

class PreviewPane extends Component {

    constructor() {
        super();
        this.state = {
            fixed: 0,
            mode:"desktop",
            email:""
        };
        this.sendMail = this.sendMail.bind(this);
    }
    componentDidMount() {
    }

    select(mode) {
        this.setState({
            mode
        });
    }

    sendMail(e) {
        e.preventDefault();
        const email = this.state.email;
        fetch('/api/components/'+this.props.componentid+'/sendmail?email='+email).then(() => {
            if(this.props.onInfo)
                this.props.onInfo('Email sent!');
        });
    }

    render() {
        if(!this.props.componentid) {
            return <div></div>;
        }

        const componentid = (this.state.fixed&&this.state.fixed != "0")?this.state.fixed:this.props.componentid;

        const size = this.state.mode=="desktop"?640:340;

        let componentmenu = this.props.projectcomponents.menu();

        return (
            <div className={"uk-flex uk-flex-column"} style={{height:"100%", width:size, overflow:"hidden", position:"absolute", top:0, right:0, bottom:0}}>
                <div className="uk-flex uk-width-1-1" style={{backgroundColor:"#fff", padding: 10}}>
                    <div className="uk-width-expand">
                        <select
                            className="uk-select uk-form-width-large"
                            value={this.state.fixed}
                            name="fixed"
                            onChange={ (e) => this.setState({fixed: e.target.value})}>
                            <option key={0} value={0}>This component</option>
                            {componentmenu.map( item => {
                                return <option key={item.id} value={item.id}>{item.name}</option>;
                            })}
                        </select>
                    </div>
                    <ul className="uk-iconnav">
                        <li className={this.state.mode=='desktop'?'uk-active':null}><a href="#" onClick={e => this.select("desktop") } data-uk-icon="icon: desktop">&nbsp;</a></li>
                        <li className={this.state.mode=='phone'?'uk-active':null}><a href="#" onClick={e => this.select("phone") } data-uk-icon="icon: phone">&nbsp;</a></li>
                        <li className={this.state.mode=='closed'?'uk-active':null}><a href="#" onClick={e => this.select("closed") } data-uk-icon="icon: close">&nbsp;</a></li>
                    </ul>
                </div>
                {this.state.mode!='closed'?<PreviewIframe src={"/api/components/"+componentid+"/render"+"?refresh="+this.props.refreshkey}>
                </PreviewIframe>:''}
                <div className={"uk-flex"} style={{backgroundColor:"#fff", padding: 10}}>
                    <ul className="uk-iconnav" style={{paddingRight: 40}}>
                        <li ><a href={"/api/components/"+componentid+"/render"+"?refresh="+this.props.refreshkey} data-uk-icon="icon: desktop" target="_blank">New window </a></li>
                    </ul>
                    <form onSubmit={this.sendMail} className="uk-width-expand">
                        <input className="uk-input uk-form-width-medium" type="text" name="email" value={this.state.email} onChange={e => this.setState({email:e.target.value})}/>
                        <button className="uk-button" data-uk-icon="icon: mail"></button>
                    </form>
                </div>
            </div>
        );
    }
}

export default withComponents(PreviewPane);