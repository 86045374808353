import React, { Component } from 'react';
import {Controlled as CodeMirror} from 'react-codemirror2'
require('codemirror/mode/xml/xml');

import ParameterList from './ParameterList';
import ComponentProvider from '../component/ComponentProvider';
import PreviewPane from '../layout/PreviewPane';

import ReactDOM from "react-dom";
import {withComponents} from "./ComponentProvider";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class ComponentView extends Component {

    constructor() {
        super();
        this.state = {
            components: [],
            refreshkey: Math.floor(Math.random()*10000)
        }
        this.handleChange = this.handleChange.bind(this);
        this.save = this.save.bind(this);
        this.addParameter = this.addParameter.bind(this);
        this.removeParameter= this.removeParameter.bind(this);
        this.parameterChange = this.parameterChange.bind(this);

    }
    componentDidMount() {
        this.load(this.props.componentid);
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        if(nextProps.componentid != this.props.componentid) {
            this.load(nextProps.componentid);
        }
    }
    load(id) {
        /* fetch API in action */
        fetch('/api/components/'+id)
            .then(response => {
                return response.json();
            })
            .then(component => {
                // component.config = JSON.parse(component.config);
                // if(!Array.isArray(component.config))
                //     component.config = [];
                // component.content= JSON.parse(component.content);
                // if(component.content == null || !typeof(component.content) == "object")
                //     component.content = {};
                this.setState({
                    component: component,
                    refreshkey: Math.floor(Math.random()*10000)
                });
            });
    }
    handleChange(field, value) {
        var component = this.state.component;
        component[field] = value;
        this.setState({ component });
    }
    save(e) {
        e.preventDefault();

        const component = Object.assign({}, this.state.component);
        component.parameters = null;

        let config = this.state.component.config.filter(param => !param.inherited );
        component.config = JSON.stringify(config);
        component.content = JSON.stringify(this.state.component.content);

        let subcomponents = this.state.component.subcomponents.filter(c => !c.inherited);
        component.subcomponents = JSON.stringify(subcomponents.map( comp => {
            comp.config = {};
            comp.subcomponents = [];
            return comp;
        }));

        fetch( '/api/components/' + component.id, {
            method:'put',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(component)
        })
            .then(response => {
                return response.json();
            })
            .then( data => {
                this.load(this.props.componentid);
                if(this.props.onChange) {
                    this.props.onChange();
                }
                toast.info("Saved!");
            })

    }
    parameterChange(name, value) {
        let comp = this.state.component;
        let content = this.state.component.content;
        content[name] = value;
        this.setState({component:comp});
    }
    addParameter(param) {
        let comp = this.state.component;
        let config= this.state.component.config;
        config.push(param);
        comp.config = config;
        this.setState({component:comp});
    }
    removeParameter(name) {
        let comp = this.state.component;
        let config= this.state.component.config.filter(item => item.name != name);
        comp.config = config;
        let content = this.state.component.content;
        if(content[name])
            delete content[name];
        comp.content = content;
        this.setState({component:comp});
    }
    handleInfo(info) {
        toast.info(info);
    }
    render() {
        if(!this.state.component) {
            return <div></div>;
        }
        let config = this.state.component.config;
        let content = this.state.component.content;
        // let subcomponents = this.state.component.subcomponents;
        let componentmenu = this.props.projectcomponents.parentMenu(this.state.component.id, this.state.component.type);
        return (
            <div style={{overflow:"hidden", height:"100%", position: "absolute", top:0, left:0, right:0, bottom:0}}>
                <ToastContainer
                  position="bottom-center"
                  autoClose={5000}
                  hideProgressBar
                  newestOnTop={false}
                  closeOnClick
                  rtl={false}
                  pauseOnVisibilityChange
                  draggable={false}
                  pauseOnHover
                />
                <div style={{height:"100%", overflowY:"scroll", position:"absolute", top:0, left:0, right:640, bottom:0}}>
                    <form onSubmit={this.save} className="uk-form-horizontal" style={{paddingBottom:80}}>
                        <div className="uk-margin">
                            <label className="uk-form-label">Name</label>
                            <div className="uk-form-controls">
                                <input
                                    className="uk-input uk-form-width-large"
                                    type='text'
                                    value={this.state.component.name}
                                    required
                                    name="name"
                                    onChange={ (e) => this.handleChange("name", e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="uk-margin">
                            <label className="uk-form-label">Type</label>
                            <div className="uk-form-controls">
                                <select
                                    className="uk-select uk-form-width-large"
                                    value={this.state.component.type}
                                    name="type"
                                    onChange={ (e) => this.handleChange("type", e.target.value)}>
                                    <option value="element">Element</option>
                                    <option value="module">Module</option>
                                    <option value="master">Master</option>
                                    <option value="email">Email</option>
                                </select>
                            </div>
                        </div>
                        <div className="uk-margin">
                            <label className="uk-form-label">Extends</label>
                            <div className="uk-form-controls">
                                <select
                                    className="uk-select uk-form-width-large"
                                    value={this.state.component.parent_id}
                                    name="parent_id"
                                    onChange={ (e) => this.handleChange("parent_id", e.target.value)}>
                                    <option key={0} value={0}>Nothing</option>
                                    {componentmenu.map( item => {
                                        return <option key={item.id} value={item.id}>{item.name}</option>;
                                    })}
                                </select>
                              &nbsp; <a className="uk-button uk-button-default uk-button-small" href={"/components/"+this.state.component.parent_id}>...</a>
                            </div>
                        </div>
                        <h3>Parameters</h3>
                        <div className="uk-margin">
                            <label className="uk-form-label">Content parameters</label>
                            <div className="uk-form-controls">
                                <input
                                    className="uk-input uk-form-width-large"
                                    type='text'
                                    value={this.state.component.contentparams}
                                    name="contentparams"
                                    onChange={ (e) => this.handleChange("contentparams", e.target.value)}
                                />
                            </div>
                        </div>
                        <ParameterList
                            config={config}
                            content={content}
                            onContentChange={this.parameterChange}
                            addParameter={this.addParameter}
                            removeParameter={this.removeParameter}
                        />
                        <h3>Template</h3>
                        <CodeMirror
                            value={this.state.component.template}
                            options={{
                                mode : "xml",
                                htmlMode: true,
                                lineNumbers: true
                            }}
                            onBeforeChange={ (editor, data, value) => this.handleChange("template", value)}
                            onChange={(editor, data, value) => {}}
                            style={{height: "auto"}}
                        />
                        <div style={{position:"fixed",bottom:40,left:40, zIndex:100, backgroundColor:"#fff"}}>
                            <button className="uk-button uk-button-default">Save</button>
                        </div>
                    </form>
                </div>
                <PreviewPane componentid={this.state.component.id} refreshkey={this.state.refreshkey} onInfo={this.handleInfo}/>
            </div>
        );
    }
}

const ComponentViewWithStore = withComponents(ComponentView);

export default ComponentViewWithStore;

if (document.getElementById('component')) {
    const componentid = document.getElementById('component').getAttribute("data-id");
    const projectid = document.getElementById('component').getAttribute("data-projectid");
    ReactDOM.render(
        <ComponentProvider projectid={projectid}>
            <ComponentViewWithStore componentid={componentid}/>
        </ComponentProvider>,
        document.getElementById('component')
    );
}
