import React, { Component } from 'react';

import styled from 'styled-components';
import ParameterList from './ParameterList';
import { withComponents } from './ComponentProvider';

const ComponentList = styled.div`
    
`;

class AddComponent extends Component {
    constructor() {
        super();
        this.state = {
            param : {
                name: '',
                type: 'string',
            }
        };
        this.submit = this.submit.bind(this);
    }
    handleChange(name, value) {
        let param = this.state.param;
        param[name] = value;
        this.setState( {param});
    }
    submit(e) {
        e.preventDefault();
        if(this.props.onSubmit) {
            this.props.onSubmit(this.state.param);
        }
        this.setState({
            param : {
                name: '',
                type: 'string',
            }
        });
    }
    render() {
        return <div>Component selector</div>
    }
}

class SubComponentList extends Component {

    constructor() {
        super();
        this.addParameter = this.addParameter.bind(this);
    }

    componentDidMount() {
    }

    addParameter(param) {
        if(this.props.addParameter)
            this.props.addParameter(param);
    }

    removeParameter(name) {
        if(this.props.removeParameter)
            this.props.removeParameter(name);
    }

    setContent(name, value) {
        if(this.props.onContentChange)
            this.props.onContentChange(name, value);
    }

    getContent(name) {
        if(this.props.content[name])
            return this.props.content[name];
        return "";
    }

    render() {
        var i=0;
        return <ComponentList>
            {this.props.subcomponents.map(config => {
                const type = this.props.components.lookup(config.type);
                return <div key={i++}>Huhu ({type}
                        <ParameterList
                            config={component.config}
                            content={config.config}
                            onContentChange={this.parameterChange}
                            addParameter={this.addParameter}
                            removeParameter={this.removeParameter}
                        />
                    </div>
            }

            )}
            <AddComponent/>
        </ComponentList>
    }
}

export default withComponents(SubComponentList);