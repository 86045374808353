import React, { Component } from 'react';

import styled from 'styled-components';

class AddParameter extends Component {
    constructor() {
        super();
        this.state = {
            param : {
                name: '',
                type: 'string',
            }
        };
        this.submit = this.submit.bind(this);
    }
    handleChange(name, value) {
        let param = this.state.param;
        param[name] = value;
        this.setState( {param});
    }
    submit(e) {
        e.preventDefault();
        if(this.props.onSubmit) {
            this.props.onSubmit(this.state.param);
        }
        this.setState({
            param : {
                name: '',
                type: 'string',
            }
        });
    }
    render() {
        return <tr key="addform">
            <td><input className="uk-input" type="text" value={this.state.param.name} placeholder="Name" onChange={e => this.handleChange('name', e.target.value)}/></td>
            <td><select className="uk-select" value={this.state.param.type} onChange={e => this.handleChange('type', e.target.value)}>
                <option>string</option>
                <option>lines</option>
                <option>asset</option>
            </select></td>
            <td><button onClick={this.submit} data-uk-icon="plus"></button></td>
        </tr>
    }
}

export default class ParameterList extends Component {

    constructor() {
        super();
        this.addParameter = this.addParameter.bind(this);
    }

    componentDidMount() {
    }

    addParameter(param) {
        if(this.props.addParameter)
            this.props.addParameter(param);
    }

    removeParameter(name) {
        if(this.props.removeParameter)
            this.props.removeParameter(name);
    }

    setContent(name, value) {
        if(this.props.onContentChange)
            this.props.onContentChange(name, value);
    }

    getContent(name) {
        if(this.props.content[name])
            return this.props.content[name];
        return "";
    }

    render() {
        const configValues = this.props.config;

        const params = this.props.params ? this.props.params.split(',') : [];
        console.log(params);

        const filtered = params.length === 0 ? configValues : configValues.filter(item => params.includes(item.name));

        const sortedByName = filtered.sort((a,b) => {
            return a.name > b.name ? 1 : -1;
        });

        const sorted = sortedByName.sort((a,b) => {
            const levela = a.level ? a.level : 0;
            const levelb = b.level ? b.level : 0;
            return levela > levelb ? -1 : 1;
        });

        const grouped = sorted.reduce((hash, obj) => ({...hash, [obj["component"]]:( hash[obj["component"]] || [] ).concat(obj)}), {})


        return <table className="uk-table uk-table-small" style={{tableLayout: "fixed", width:"100%", marginLeft: -12}}>
            <thead>
                <tr>
                    <th>Name/Type</th>
                    <th style={{ minWidth: "60%"}}>Value/Default</th>
                    <th style={{ width:30}}></th>
                </tr>
            </thead>
            <tbody>
            {Object.entries(grouped).map(
                ([key, values], i) => {
                    const level = values[0].level ?? 0;
                    return (
                        <React.Fragment key={i}>
                            <tr>
                                <th colSpan={3}>{key === 'undefined' ? 'Local' : key}</th>
                            </tr>
                            {values.map(
                                (param, j) => (
                                    <tr key={j}>
                                        <td>{param.name}<br/><em style={{color: '#999'}}>{param.type}</em></td>
                                        <td>
                                            {param.type === "lines" ?
                                                <textarea className="uk-textarea"
                                                          rows="4"
                                                          onChange={e => this.setContent(param.name, e.target.value)}
                                                          value={this.getContent(param.name)}/>
                                                : <input className="uk-input"
                                                         type="text"
                                                         value={this.getContent(param.name)}
                                                         onChange={e => this.setContent(param.name, e.target.value)}
                                                />}
                                            <em style={{color: '#999'}}>{param.default}</em>
                                        </td>
                                        <td>{param.inherited ? '' :
                                            <button onClick={e => this.removeParameter(param.name)}
                                                    data-uk-icon="trash"></button>}</td>
                                    </tr>
                                )
                            )}
                        </React.Fragment>
                    );
                }
            )}
            {this.props.addParameter?<AddParameter onSubmit={this.addParameter}/>:null}
            </tbody>
        </table>
    }
}
