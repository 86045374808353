import React, { Component, Children } from "react";
import PropTypes from 'prop-types';

class ComponentStore extends Component {
    constructor() {
        super();
        this.init= this.init.bind(this);
        this.lookup = this.lookup.bind(this);
        this.ready = false;
    }

    init(id, callback) {
        fetch('/api/components/?menu=1&project='+id)
            .then(response => {
                return response.json();
            })
            .then(components => {
                this.components = components;
                this.componentmap = components.reduce(function(map, obj) {
                    map[obj.id] = obj;
                    return map;
                }, {});
                this.componentsbytype = components.reduce(function(map, obj) {
                    map[obj.type].push(obj);
                    return map;
                }, {
                    email:[],master:[],module:[],element:[]
                });
                this.ready = true;
                if(callback)
                    callback();

            });
    }

    lookup(id) {
        return this.componentmap[id];
    }

    menu() {
        return this.components;
    }

    subcomponentmenu(type) {
        if(type == "master" || type == "element")
            return [];

        let subtype = "";
        if(type == "email")
            subtype = "module";
        if(type == "module")
            subtype = "element";

        return this.componentsbytype[subtype];
    }

    parentMenu(id, type) {
        if(type=="email")
            type = "master"
        return this.componentsbytype[type].filter( c => {
            return (c.id != id);
        });
    }

}


class ComponentProvider extends Component {
    constructor() {
        super();
        this.components = new ComponentStore();
        this.storeReady = this.storeReady.bind(this);
        this.state = {
            refreshkey: Math.floor(Math.random()*10000)
        }
    }
    componentDidMount() {
        this.components.init(this.props.projectid, this.storeReady);
    }
    storeReady() {
        this.setState({
            refreshkey: Math.floor(Math.random()*10000)
        })
    }
    getChildContext() {
        return {
            projectcomponents: this.components
        }
    }
    render() {
        // `Children.only` enables us not to add a <div /> for nothing
        if(!this.components.ready) return null;

        return Children.only(this.props.children)
    }
}
ComponentProvider.childContextTypes = {
    projectcomponents: PropTypes.object.isRequired,
};
export default ComponentProvider;


export const withComponents = (ComponentToWrap) => {
    class ComponentsComponent extends Component {

        render() {
            // const { components } = this.context;
            // what we do is basically rendering `ComponentToWrap`
            // with an added prop, like a hook
            return (
                <ComponentToWrap {...this.props} projectcomponents={this.context.projectcomponents} />
            )
        }
    }
    ComponentsComponent.contextTypes = {
        projectcomponents: PropTypes.object.isRequired,
    }
    return ComponentsComponent;
}