import React, { Component } from 'react';
import TextField, { Textarea } from '@ied/react-enhanced-form'
import ParameterList from './ParameterList';
import SubComponentList from './SubComponentList';
import ComponentProvider from '../component/ComponentProvider';
import PreviewPane from '../layout/PreviewPane';

import styled from 'styled-components';
import ReactDOM from "react-dom";
import {withComponents} from "./ComponentProvider";
import uniqueId from 'lodash/uniqueId';
import Sortable from 'react-sortablejs';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class SubComponent extends Component {
    constructor() {
        super();
        this.state = {
            name: '',
            group: '',
            editing: false,
            content: {}
        };
        this.remove = this.remove.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onContentChange = this.onContentChange.bind(this);
    }

    onChange(field, value) {
        if(this.props.onChange) {
            this.props.onChange(this.props.id, field, value);
        }
    }

    remove() {
        if(this.props.onRemove) {
            this.props.onRemove(this.props.id);
        }
    }

    onContentChange(name, value) {
        if(this.props.onContentChange) {
            this.props.onContentChange(this.props.id, name, value)
        }
    }

    render() {
        return <div className="uk-padding-smallx" data-id={this.props.id} style={{
            position:"relative",
            borderBottom: "1px solid #ccc",
            backgroundColor: this.state.editing ? "#eee" : 'transparent',
            cursor:'grab',
            overflow: "hidden"
        }}>
            <h4 className="uk-card-title"
                onClick={e => this.setState({editing: !this.state.editing}) }
                style={{
                    cursor:'pointer',
                    margin:0,
                    paddingLeft: 12
                }}
            >
                {this.props.data.name} <em>{this.props.data.subtypename}</em>
            </h4>
            <ul className="uk-iconnav" style={{position:"absolute", top:10, right:20}}>
                <li><a href="#" onClick={e => this.remove() } data-uk-icon="icon: trash">&nbsp;</a></li>
            </ul>
            {this.state.editing?<form className="uk-form-horizontal uk-padding-small">
                <div className="uk-margin">
                    <label className="uk-form-label">Name</label>
                    <div className="uk-form-controls">
                        <input
                            type="text"
                            className="uk-input uk-form-width-large"
                            value={this.props.data.name}
                            name="name"
                            onChange={ (e) => this.onChange("name", e.target.value)}>
                        </input>
                    </div>
                </div>
                <div className="uk-margin">
                    <label className="uk-form-label">Group</label>
                    <div className="uk-form-controls">
                        <input
                            type="text"
                            className="uk-input uk-form-width-large"
                            value={this.props.data.group}
                            name="group"
                            onChange={ (e) => this.onChange("group", e.target.value)}>
                        </input>
                    </div>
                </div>
                <ParameterList
                    config={this.props.data.config}
                    params={this.props.data.contentparams}
                    content={this.props.data.content}
                    onContentChange={this.onContentChange}/>
            </form>:null}
        </div>
    }

}

class AddSubComponentImplementation extends Component {
    constructor() {
        super();
        this.state = {
            param : {
                name: '',
                type: 0,
            }
        };
        this.submit = this.submit.bind(this);
        this.componentmenu = [];
    }

    handleChange(name, value) {
        let param = this.state.param;
        param[name] = value;
        this.setState( {param});
    }
    submit(e) {
        e.preventDefault();
        let param = this.state.param;
        if(param.type == 0)
            param.type = this.componentmenu[0].id;
        if(this.props.onSubmit) {
            this.props.onSubmit(param);
        }
        this.setState({
            param : {
                name: '',
                type: 0,
            }
        });
    }
    render() {
        this.componentmenu = this.props.projectcomponents.subcomponentmenu(this.props.type);

        return <div key="addform" style={{paddingLeft:40}}>
            <input className="uk-input uk-form-width-medium" type="text" value={this.state.param.name} placeholder="Name" onChange={e => this.handleChange('name', e.target.value)}/>
            <select
                className="uk-select uk-form-width-medium"
                value={this.state.type}
                name="type"
                onChange={ (e) => this.handleChange("type", e.target.value)}>
                {this.componentmenu.map( item => {
                    return <option key={item.id} value={item.id}>{item.name}</option>;
                })}
            </select>
            <button className="uk-button uk-button-default" onClick={this.submit} data-uk-icon="plus"></button>
        </div>
    }

}

const AddSubComponent = withComponents(AddSubComponentImplementation);

class SubComponentsView extends Component {

    constructor() {
        super();
        this.state = {
            components: [],
            refreshkey: Math.floor(Math.random()*10000)
        }
        this.handleChange = this.handleChange.bind(this);
        this.save = this.save.bind(this);
        this.onSort = this.onSort.bind(this);
        this.onAdd = this.onAdd.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onContentChange = this.onContentChange.bind(this);
        this.onRemove = this.onRemove.bind(this);
    }
    componentDidMount() {
        this.load(this.props.componentid);
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        if(nextProps.componentid != this.props.componentid) {
            this.load(nextProps.componentid);
        }
    }
    load(id) {
        fetch('/api/components/'+id)
            .then(response => {
                return response.json();
            })
            .then(component => {
                this.setState({
                    component: component,
                    refreshkey: Math.floor(Math.random()*10000)
                });
            });
    }
    handleChange(field, value) {
        var component = this.state.component;
        component[field] = value;
        console.log(component);
        this.setState({ component });
    }
    save(e) {
        if(e)
            e.preventDefault();

        const component = Object.assign({}, this.state.component);
        component.parameters = null;

        let config = this.state.component.config.filter(param => !param.inherited );
        component.config = JSON.stringify(config);
        component.content = JSON.stringify(this.state.component.content);

        let subcomponents = this.state.component.subcomponents.filter(c => !c.inherited);
        component.subcomponents = JSON.stringify(subcomponents.map( comp => {
            comp.config = {};
            comp.subcomponents = [];
            return comp;
        }));

        console.log("HU");
        console.log(component.subcomponents);

        fetch( '/api/components/' + component.id, {
            method:'put',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(component)
        })
            .then(response => {
                return response.json();
            })
            .then( data => {
                this.load(this.props.componentid);
                toast.info("Saved!");
                if(this.props.onChange) {
                    this.props.onChange();
                }
            })

    }
    /*
     * Callback for sortable
     */
    onSort(order, sortable, evt) {
        var component = this.state.component;
        component.subcomponents = order.map( index => this.state.component.subcomponents[index] );
        this.setState({component});
        this.save();
    }
    onChange(index, name, value) {
        const subcomponent = this.state.component.subcomponents[index];
        subcomponent[name] = value;
        this.setState({component:this.state.component});
    }
    onContentChange(index, name, value) {
        const subcomponent = this.state.component.subcomponents[index];
        subcomponent.content[name] = value;
        this.setState({component:this.state.component});
    }
    onAdd(data) {
        var component = this.state.component;
        component.subcomponents.push({
            name: data["name"],
            type: parseInt(data["type"]),
            content: {}
        });
        this.save();
        // this.setState({component});
    }
    onRemove(index) {
        var component = this.state.component;
        component.subcomponents.splice(index, 1);
        this.save();
    }
    handleInfo(info) {
        toast.info(info);
    }
    render() {
        if(!this.state.component) {
            return <div></div>;
        }
        let subcomponents = this.state.component.subcomponents.filter(c => !c.inherited);
        let i = 0;

        return (
            <div style={{overflow:"hidden", height:"100%", position: "absolute", top:0, left:0, right:0, bottom:0}}>
                <div style={{overflowY:"scroll", position:"absolute", top:0, left:0, right:640, bottom:60}}>
                    <ToastContainer
                        position="bottom-center"
                        autoClose={5000}
                        hideProgressBar
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnVisibilityChange
                        draggable={false}
                        pauseOnHover
                    />
                    <Sortable
                        options={{
                        }}
                        onChange={this.onSort}
                    >
                    {subcomponents.map(config => {
                        // const compconfig = this.props.projectcomponents.lookup(config.type);
                            return <SubComponent key={i} id={i++} data={config} onRemove={this.onRemove} onContentChange={this.onContentChange} onChange={this.onChange}/>
                        }

                    )}
                    </Sortable>
                  <div style={{position:"fixed",bottom:40,left:40, zIndex:100, backgroundColor:"#fff", display:"flex"}}>
                    <button className="uk-button uk-button-primary" onClick={this.save}>Save</button>
                    <AddSubComponent onSubmit={this.onAdd} type={this.state.component.type}/>
                  </div>
                </div>
                <PreviewPane componentid={this.state.component.id} refreshkey={this.state.refreshkey} onInfo={this.handleInfo}/>
            </div>
        );
    }
}

const SubComponentsWithStore = withComponents(SubComponentsView);

export default SubComponentsWithStore;

if (document.getElementById('subcomponents')) {
    const componentid = document.getElementById('subcomponents').getAttribute("data-id");
    const projectid = document.getElementById('subcomponents').getAttribute("data-projectid");
    ReactDOM.render(
        <ComponentProvider projectid={projectid}>
            <SubComponentsWithStore componentid={componentid}/>
        </ComponentProvider>,
        document.getElementById('subcomponents')
    );
}
