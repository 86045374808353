import Dropzone from 'dropzone';


Dropzone.options.myDropzone = {
    paramName: 'file',
    maxFilesize: 5, // MB
    maxFiles: 20,
    dictDefaultMessage: 'Click here to upload files or drop files.',
    // acceptedFiles: ".jpeg,.jpg,.png,.gif",
    init: function() {
        this.on("success", function(file, response) {
            // var a = document.createElement('span');
            // a.className = "thumb-url btn btn-primary";
            // a.setAttribute('data-clipboard-text','{{url('/uploads')}}'+'/'+response);
            // a.innerHTML = "copy url";
            // file.previewTemplate.appendChild(a);
            window.location.reload();
        });
    }
}
